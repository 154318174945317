<template>
  <div>
    <!-- Table -->
    <b-table
      id="facturasTable"
      v-if="getAvailableList"
      :fields="fields"
      :items="getResourceList"
      striped
      bordered
      responsive
      hover
      stacked="lg"
      show-empty
      small
    >
      <!-- Eliminado -->
      <template #cell(is_deleted)="row">
        <b-badge v-if="row.item.is_deleted" variant="danger">No</b-badge>
        <b-badge v-else variant="light">Sí</b-badge>
      </template>

      <!-- Nombre -->
      <template #cell(subcondominio)="row">
        <span :class="{ 'text-muted': row.item.is_deleted }">{{ row.item.subcondominio }}</span>
      </template>

      <template #cell(dueno)="row">
        <span :class="{ 'text-muted': row.item.is_deleted }">{{ row.item.dueno.persona.nombre_completo }}</span>
      </template>

      <template #cell(responsable)="row">
        <span :class="{ 'text-muted': row.item.is_deleted }">{{ row.item.responsable.persona.nombre_completo }}</span>
      </template>

      <!-- Detalle -->
      <template #cell(show_details)="row">
        <b-button
          @click="row.toggleDetails"
          variant="light"
          class="btn-sm mb-2 btn-block btn-outline-black"
        >
          Detalles
        </b-button>
      </template>

      <!-- Rentas -->
      <template #cell(show_rentas)="row">
        <b-button
          @click="showRentas(row.item.id)"
          variant="white"
          class="btn-sm mb-2 btn-block btn-outline-green"
        >
          Rentas
        </b-button>
      </template>

      <!-- Acciones -->
      <template #cell(acciones)="row">
        <div class="d-flex justify-content-center" v-if="!row.item.is_deleted">
          <u-d-controls
            :id="row.item.id"
            editPath="facturaModule/getResourceToEdit"
            deletePath="facturaModule/deleteResource"
            :editPermissions="['actualizar facturas']"
            :deletePermissions="['eliminar facturas']"
            @on-got-item="emitOnGotItem"
          ></u-d-controls>
        </div>
      </template>

      <!-- Acciones -->
      <template #row-details="row">
        <factura-detalles :row="row"></factura-detalles>
        <!-- El botón de "ver como", únicamente lo puede usar el usuario con rol "admin" -->
      </template>
    </b-table>
    <loading v-else></loading>

    <!--div v-if="isThereData">
      <paginator
        aria-controls="usersTable"
        :disabled="!getAvailableList"
        show-path="userModule/getUsers"
        :resource="getResourceList"
      ></paginator>
    </div-->
    <!-- End Table -->
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import FacturaDetalles from './FacturaDetalles'

const STORE_MODULE = 'facturaModule'

const response = () => ({
  error: false,
  message: '',
  errors: {}
})

export default {
  name: 'FacturaList',

  components: {
    FacturaDetalles
  },

  created () {
    this.submit()
  },

  data () {
    return {
      selectedId: null,

      showRentasModal: false,

      isLoading: false,

      response: response(),

      fields: [
        { key: 'cfdi', label: 'CFDI', sortable: true },
        { key: 'emisor', label: 'RFC', sortable: true },
        { key: 'inversionista.persona.nombre_completo', label: 'Inversionista', sortable: true },
        { key: 'periodo.descriptor', label: 'Período', sortable: true },
        { key: 'status', label: 'Status', sortable: true }
      ]
    }
  },

  computed: {
    ...mapGetters(STORE_MODULE, [
      'getResourceList',
      'getAvailableList',
      'isThereData',
      'getResource'
    ])
  },

  methods: {
    async submit () {
      const { error, message } = await this.$store.dispatch(`${STORE_MODULE}/getResource`)

      if (error) this.$notify({ error, message }, 'Facturas')
    },

    async showRentas (id) {
      this.showRentasModal = true
      this.isLoading = true
      await this.$store.dispatch(`${STORE_MODULE}/getRentas`, id)
      this.isLoading = false
    },

    hideRentas () {
      this.showRentasModal = false
    },

    emitOnGotItem () {
      this.$emit('on-got-item')
    }
  }
}
</script>

<style scoped>

</style>
