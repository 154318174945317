<template>
  <div>
    <slot name="activator" v-bind:onShowModal="onShowModal">
      <b-button @click="onShowModal" variant="success" size="sm">Crear</b-button>
    </slot>

    <b-modal
      v-model="modal"
      centered
      size="lg"
      scrollable
      no-close-on-backdrop
      no-close-on-esc
      hide-header-close
      dialog-class="x-modal-md"
    >
      <template #modal-title>
        <span :class="isEditingResource ? 'text-danger' : ''">{{ showFormTitle }}</span>
      </template>

      <x-alert-with-errors
        :error="response.error"
        :title="response.message"
        :errors="response.errors"
      />

      <b-overlay :show="isLoading">
        <template #overlay>
          <div class="text-center">
            <loading message="Procesando información, por favor espere..." />
          </div>
        </template>

        <b-card :class="{ isLoading }">
          <FormulateForm
            ref="facturaForm"
            name="facturaForm"
            v-model="formValues"
            :errors="response.errors"
            @submit="submit"
          >
            <b-row>
              <!-- Vigencia -->
              <b-col cols="12" md="12">
                <FormulateInput
                  name="vigencia"
                  label="Vigencia"
                  required
                  type="text"
                  validation="required"
                  validation-name="Vigencia"
                />
              </b-col>

              <!-- Fecha de Apertura -->
              <b-col cols="12" md="6">
                <FormulateInput
                  name="fecha_apertura"
                  label="Fecha de Apertura"
                  required
                  type="date"
                  validation="bail|required"
                  validation-name="Fecha Apertura"
                />
              </b-col>

              <!-- Fecha de Vencimiento -->
              <b-col cols="12" md="6">
                <FormulateInput
                  name="fecha_vencimiento"
                  label="Fecha de Vencimiento"
                  required
                  type="date"
                  validation="bail|required"
                  validation-name="Fecha Vencimiento"
                />
              </b-col>
            </b-row>

            <!-- Fecha de Firma -->
            <FormulateInput
              name="fecha_firma"
              label="Fecha de Firma"
              required
              type="date"
              validation="bail|required"
              validation-name="Fecha Firma"
            />

            <!-- Forma de Pago -->
            <FormulateInput
              name="forma_pago"
              label="Forma de pago"
              required
              type="text"
              validation="required"
              validation-name="Forma Pago"
            />

            <!-- Importe -->
            <FormulateInput
              name="importe"
              label="Importe"
              required
              type="text"
              validation="bail|required"
              validation-name="Importe"
            />

            <!-- Ret. ISR -->
            <FormulateInput
              name="ret_isr"
              label="Retención ISR"
              required
              type="text"
              validation="bail|required"
              validation-name="Ret. ISR"
            />

            <!-- Ret. IVA -->
            <FormulateInput
              name="ret_iva"
              label="Retención IVA"
              required
              type="text"
              validation="bail|required"
              validation-name="Ret. IVA"
            />

            <!-- Fideicomiso -->
            <FormulateInput
              name="fideicomiso"
              label="Fideicomiso"
              required
              type="text"
              validation="bail|required"
              validation-name="Fideicomiso"
            />

            <!-- Responsable -->
            <FormulateInput
              name="inversionista_id"
              label="Inversionista"
              required
              type="text"
              validation="bail|required"
              validation-name="Inversionista"
            />

            <!-- Desfase -->
            <FormulateInput
              name="desfase"
              label="Desfase"
              required
              type="text"
              validation="bail|required"
              validation-name="Desfase"
            />

            <!-- Amueblado -->
            <FormulateInput
              name="amueblado"
              label="Amueblado"
              required
              type="text"
              validation="bail|required"
              validation-name="Amueblado"
            />

            <!-- Vigente -->
            <FormulateInput
              name="vigente"
              label="Vigente"
              required
              type="text"
              validation="bail|required"
              validation-name="Vigente"
            />

          </FormulateForm>
        </b-card>
      </b-overlay>

      <template #modal-footer>
        <!-- Enviar para crear o actualizar -->
        <div class="w-100">
          <x-form-footer-buttons
            :disabled="$refs.facturaForm ? $refs.facturaForm.hasErrors : false"
            :loading="isLoading"
            :isEditing="isEditingResource"
            :isCreating="!isEditingResource"
            @on-cancel="resetForm"
            @on-create="submit"
            @on-update="submit"
          ></x-form-footer-buttons>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

const formValues = () => ({
  vigencia: '',
  fecha_apertura: '',
  fecha_vencimiento: '',
  fecha_firma: '',
  forma_pago: '',
  importe: '',
  ret_isr: '',
  ret_iva: '',
  fideicomiso: '',
  inversionista_id: '',
  desfase: '',
  amueblado: '',
  vigente: ''
})

const response = () => ({
  error: false,
  message: '',
  errors: {}
})

const STORE_MODULE = 'facturaModule'

export default {
  name: 'FacturaForm',

  props: {
    value: {
      type: Boolean,
      required: true
    }
  },

  data () {
    return {
      modal: this.value,

      isLoading: false,

      formValues: formValues(),

      response: response()
    }
  },

  computed: {
    //
    // Factura module
    ...mapState(STORE_MODULE, ['editingResource']),
    ...mapGetters(STORE_MODULE, ['isEditingResource']),

    showFormTitle () {
      return this.isEditingResource
        ? `Editando factura: '${this.editingResource.subcondominio}'`
        : 'Crear nueva factura'
    }
  },

  watch: {
    isEditingResource: {
      handler (value) {
        if (value) {
          const {
            id,
            vigencia,
            fecha_apertura,
            fecha_vencimiento,
            fecha_firma,
            forma_pago,
            importe,
            ret_isr,
            ret_iva,
            fideicomiso,
            inversionista_id,
            desfase,
            amueblado,
            vigente
          } = this.editingResource
          this.formValues.id = id
          this.formValues.vigencia = vigencia
          this.formValues.fecha_apertura = fecha_apertura
          this.formValues.fecha_vencimiento = fecha_vencimiento
          this.formValues.fecha_firma = fecha_firma
          this.formValues.forma_pago = forma_pago
          this.formValues.importe = importe
          this.formValues.ret_isr = ret_isr
          this.formValues.ret_iva = ret_iva
          this.formValues.fideicomiso = fideicomiso
          this.formValues.inversionista_id = inversionista_id
          this.formValues.desfase = desfase
          this.formValues.amueblado = amueblado
          this.formValues.vigente = vigente
          return
        }

        this.resetForm()
      }
    },

    value: {
      immediate: true,
      handler (value) {
        this.modal = value
      }
    },

    modal: {
      immediate: true,
      handler (value) {
        this.$emit('input', value)
      }
    }
  },

  methods: {
    /**
     * Envía el formulario.
     *
     * Envía el formulario para su creación o su actualización.
     * Dependiendo si la variable "isEditingUser" es verdadera actualizará
     * si no es así entonces mandará crear.
     */
    async submit () {
      console.log('submit')
      this.response = response()
      this.prepareInput()

      this.isLoading = true
      const { error, message, data } = this.isEditingResource
        ? await this.updateResource(this.formValues)
        : await this.createResource(this.formValues)
      this.isLoading = false

      this.$notify({ error, message }, 'Envío de formulario')

      this.response.error = error
      this.response.message = message
      this.response.errors = error ? data.errors : {}

      if (!error) this.resetForm()
    },

    async createResource (form) {
      return await this.$store.dispatch(`${STORE_MODULE}/createResource`, form)
    },

    async updateResource (form) {
      return await this.$store.dispatch(`${STORE_MODULE}/updateResource`, {
        id: form.id,
        payload: form
      })
    },

    /**
     * Prepara los datos antes de enviarlos al backend.
     *
     * Prepara los datos necesarios como la fecha o los roles y permisos que
     * se deben procesar en el backend.
     */
    prepareInput () {
    },

    resetForm () {
      document.activeElement.blur()
      this.$store.commit(`${STORE_MODULE}/setResourceToEdit`, null)
      this.$formulate.reset('facturaForm')
      this.formValues = formValues()
      this.response = response()

      this.modal = false
    },

    onShowModal () {
      this.modal = true
      this.$emit('input', this.modal)
    }
  }
}
</script>

<style>
.required_form {
  color: red;
}
.isLoading {
  max-height: 400px !important;
  overflow: hidden;
}
</style>
